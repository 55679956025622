import {
  complexCharts,
  composedCharts,
  metricChartType,
  requestMetrics,
  factsMeanMetrics,
  parentMetric,
  sleepDepthLevels,
  rawBandStates,
} from '@constants/charts';
import { mealDefaultTime, mealsTypes } from '@constants/generatedData';
import { getTime, getUnixTime, isMatch, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { groupBy, isEmpty, map, uniq, uniqBy, round, isNil, flatMap } from 'lodash';
import {
  BandRemovedOption,
  ChartType,
  ComposedMetric,
  DateRange,
  ExternalTransform,
  Metric,
  SleepDepthOption,
  TransformMetricsConfig,
  TransformMetricsDimensions,
  TransformParams,
  TransformParamsConfig,
} from 'models/charts';
import { getConvertedValue, getGlobalTimezoneOffset } from './generatedData';
import { KG_TO_POUND } from '@constants/global';
import { ActionEvidenceData } from 'models/action';

export const getRequestInfo = (metrics: Metric[], dateRange: DateRange): any =>
  dateRange > 2
    ? {
        type: 'events',
        table: 'events.daily',
        metrics: metrics.map((item) => requestMetrics[item]),
      }
    : {
        type: 'ticks',
        table: undefined,
        metrics: metrics.map((item) => (item === 'spo2' ? 'spo2%3D80' : item)),
      };

export const getOptionMetrics = (
  metric: Metric | ComposedMetric,
  dateRange: DateRange
): Metric | Metric[] => {
  if (metric === 'band_removed' && dateRange < 7) {
    return 'band_removed';
  }

  return metric in composedCharts ? composedCharts[metric] : metric;
};

export const getMetricArray = (metric: Metric | ComposedMetric, dateRange: DateRange): Metric[] => {
  if (metric === 'band_removed' && dateRange < 7) {
    return ['band_removed'];
  }

  if (dateRange > 2 && metric === 'blood_pressure') {
    return complexCharts['blood_pressure_mean'];
  }

  if (metric in composedCharts) {
    return composedCharts[metric];
  }

  if (metric in complexCharts) {
    return complexCharts[metric];
  }

  return [metric];
};

export const getSmaMetrics = (metrics: (Metric | ComposedMetric)[]): string[] =>
  metrics
    .map((metric: Metric | ComposedMetric) =>
      !['meals', 'sleep', 'band_removed'].includes(metric) ? `${metric}_mean_sma_12h` : null
    )
    .filter((i) => i) as string[];

export const getChartType = (metric: Metric, data: any, dateRange: DateRange): ChartType => {
  if (metricChartType[metric] === 'basicLine') {
    if (dateRange <= 2 && data[metric] && data[metric].length === 1) {
      return 'onePointLine';
    }

    if (
      dateRange > 2 &&
      data.general &&
      data.general.filter((item: any) => !isEmpty(item.data))?.length === 1
    ) {
      return 'onePointLine';
    }
  }

  if (metric === 'band_removed' && dateRange > 2) {
    return 'composedBar';
  }

  return metricChartType[metric];
};

export const isEmptyData = (
  metric: Metric | ComposedMetric,
  data: any,
  dateRange: DateRange
): boolean => {
  if (dateRange > 2) {
    return metric === 'meals'
      ? isEmpty(data.meals)
      : isEmpty(data.general)
      ? true
      : !data.general.filter((item: any) => !isEmpty(item.data)).length;
  } else {
    return metric === 'steps'
      ? !data.steps?.some((item: any) => item.data?.steps_sum)
      : metric === 'rhr'
      ? !data.rhr?.some((item: any) => item.data?.rhr_mean)
      : !Object.values(data).some((item) => !isEmpty(item));
  }
};

const transformDimensions: TransformMetricsDimensions = {
  minMax: [
    { index: 0, name: 'timestamp', displayName: 'timestamp' },
    { index: 1, name: 'data', displayName: 'data' },
    { index: 2, name: 'min', displayName: 'min' },
    { index: 3, name: 'max', displayName: 'max' },
  ],
  tick: [
    { index: 0, name: 'timestamp', displayName: 'timestamp' },
    { index: 1, name: 'timestamp_to', displayName: 'timestamp_to' },
    { index: 2, name: 'data', displayName: 'data' },
    { index: 3, name: 'nextData', displayName: 'nextData' },
    { index: 4, name: 'firstIndex', displayName: 'firstIndex' },
    { index: 5, name: 'lastIndex', displayName: 'lastIndex' },
    { index: 6, name: 'isSimple', displayName: 'isSimple' },
    { index: 7, name: 'limitValue', displayName: 'limitValue' },
    { index: 8, name: 'intersectionX', displayName: 'intersectionX' },
    { index: 9, name: 'intersectionY', displayName: 'intersectionY' },
  ],
  smaTick: [
    { index: 0, name: 'timestamp', displayName: 'timestamp' },
    { index: 1, name: 'data', displayName: 'data' },
  ],
  bloodPressureTick: [
    { index: 0, name: 'timestamp', displayName: 'timestamp' },
    { index: 1, name: 'systolic_data', displayName: 'systolic_data' },
    { index: 2, name: 'diastolic_data', displayName: 'diastolic_data' },
  ],
  bloodPressureMinMax: [
    { index: 0, name: 'timestamp', displayName: 'timestamp' },
    { index: 1, name: 'systolic_data', displayName: 'systolic_data' },
    { index: 2, name: 'systolic_min', displayName: 'systolic_min' },
    { index: 3, name: 'systolic_max', displayName: 'systolic_max' },
    { index: 4, name: 'diastolic_data', displayName: 'diastolic_data' },
    { index: 5, name: 'diastolic_min', displayName: 'diastolic_min' },
    { index: 6, name: 'diastolic_max', displayName: 'diastolic_max' },
  ],
  mealsTick: [
    { index: 0, name: 'timestamp', displayName: 'timestamp' },
    { index: 1, name: 'type', displayName: 'type' },
    { index: 2, name: 'items', displayName: 'items' },
  ],
  logTick: [],
  bandRemovedTime: [
    { index: 0, name: 'timestamp', displayName: 'timestamp' },
    { index: 1, name: 'chargeData', displayName: 'chargeData' },
    { index: 2, name: 'removedData', displayName: 'removedData' },
    { index: 3, name: 'wearingData', displayName: 'wearingData' },
  ],
  sleepDepthTick: [
    { index: 0, name: 'start', displayName: 'start' },
    { index: 1, name: 'end', displayName: 'end' },
    { index: 2, name: 'value', displayName: 'value' },
    { index: 3, name: 'timestamp', displayName: 'timestamp' },
  ],
};

const getMinMaxData = (rawData: any, metric: Metric, tz: string) =>
  uniqBy(
    rawData.map((item: any) => {
      const timestamp = `${getTime(
        zonedTimeToUtc(startOfDay((item.timestamp || item.timestamp_from) * 1000), tz)
      )}`;
      const mean =
        metric === 'spo2' || metric === 'rmssd'
          ? item.data[`sleep_${metric}_median`]
          : item.data[`${metric}_mean`];
      const min = item.data[`${metric}_min`];
      const max = item.data[`${metric}_max`];
      return {
        timestamp,
        data: getConvertedValue(mean, metric),
        min: getConvertedValue(min, metric),
        max: getConvertedValue(max, metric),
      };
    }),
    'timestamp'
  )
    .filter(
      (item: any) =>
        !isNil(item.data) &&
        !isNaN(item.data) &&
        (item.data !== 0 || (metric === 'gsr' && item.data === 0))
    )
    .sort((a: any, b: any) => Number(a.timestamp) - Number(b.timestamp));

const getTickData = (rawData: any, metric: Metric, _: string, allData: any) => {
  let filteredData = rawData.filter(
    (item: any) =>
      item[1] || (metric === 'gsr' && item[1] === 0) || (metric === 'rhr' && item.data?.rhr_mean)
  );

  if (metric === 'heart_rate') {
    const lowHRData = allData?.low_heart_rate?.filter((item: any) => item[1]) || [];
    filteredData = [...filteredData, ...lowHRData].sort((a: any, b: any) => a[0] - b[0]);
  }

  if (metric === 'rhr') {
    return filteredData.map((item: any) => {
      const timestamp = (item.timestamp || item.timestamp_to) * 1000;
      const data = item.data?.rhr_mean;
      return {
        timestamp,
        data,
      };
    });
  } else {
    const smaData = allData?.sma
      ?.filter((item: any) => !isEmpty(item.data) && item.data?.[`${metric}_mean_sma_12h`])
      .sort((a: any, b: any) => Number(a.timestamp) - Number(b.timestamp));
    const key = `${metric}_mean_sma_12h`;

    return filteredData.map((item: any, index: number) => {
      const nextIndex = index + 1;
      const nextItem = filteredData[nextIndex] || item;

      const timestamp = item[0] * 1000;
      const timestamp_to = nextItem[0] * 1000;
      const data = getConvertedValue(item[1], metric);
      const nextData = getConvertedValue(nextItem[1], metric);
      let firstIndex =
        smaData?.findIndex(
          (item: any) =>
            item.timestamp_from * 1000 <= timestamp && item.timestamp_to * 1000 >= timestamp
        ) || 0;
      let lastIndex =
        smaData?.findIndex(
          (item: any) =>
            item.timestamp_from * 1000 <= timestamp_to && item.timestamp_to * 1000 >= timestamp_to
        ) || 0;
      firstIndex = firstIndex < 0 ? 0 : firstIndex;
      lastIndex = lastIndex < 0 ? 0 : lastIndex;

      let isSimple;
      let limitValue;
      let intersectionX;
      let intersectionY;
      if (firstIndex === lastIndex) {
        isSimple = true;
        const limitItem = smaData ? smaData[firstIndex] : null;
        const nextLimitItem = smaData ? smaData[firstIndex + 1] || limitItem : null;
        if (limitItem) {
          limitValue = getConvertedValue(limitItem.data[key], metric);
          const nextLimitValue = getConvertedValue(nextLimitItem.data[key], metric);
          const intersection = getIntersection(
            timestamp,
            data,
            timestamp_to,
            nextData,
            limitItem.timestamp_from * 1000,
            limitValue,
            limitItem.timestamp_to * 1000,
            nextLimitValue
          );
          intersectionX = intersection ? intersection.x : null;
          intersectionY = intersection ? intersection.y : null;
        }
      } else {
        isSimple = false;
      }

      return {
        timestamp,
        timestamp_to,
        data,
        nextData,
        firstIndex,
        lastIndex,
        isSimple,
        limitValue,
        intersectionX,
        intersectionY,
      };
    });
  }
};

const getSmaData = (rawData: any, metric: Metric) => {
  return rawData
    .map((item: any) => {
      const timestamp = (item.timestamp || item.timestamp_from) * 1000;
      const data = item.data[`${metric}_12h`]
        ? getConvertedValue(item.data[`${metric}_12h`], metric.split('_')[0])
        : undefined;
      return {
        timestamp,
        data,
      };
    })
    .filter((item: any) => item.data !== undefined && item.data !== 0);
};

const getBandData = (rawData: any) =>
  rawData.map((item: any) => {
    const timestamp = item[0] * 1000;
    const data = item[1] === 0 ? 3 : item[1];
    return {
      timestamp,
      data,
    };
  });

const getHourlyBarData = (rawData: any, metric: Metric) => {
  if (metric === 'bolus' || metric === 'basal') {
    return rawData
      .map((item: any) => {
        const timestamp = (item.timestamp || item.timestamp_from) * 1000 + 1800000; //add half of hour
        const data = item.data[`${metric}_sum`];
        const secData = item.data[`${metric === 'bolus' ? 'basal' : 'bolus'}_sum`];
        return {
          timestamp,
          data,
          secData,
        };
      })
      .filter(
        (item: any) =>
          item.data !== undefined && (item.data !== 0 || (item.data === 0 && item.secData !== 0))
      );
  }

  return rawData
    .map((item: any) => {
      const timestamp = (item.timestamp || item.timestamp_from) * 1000 + 1800000; //add half of hour
      const data = item.data[`${metric}_sum`];
      return {
        timestamp,
        data,
      };
    })
    .filter((item: any) => item.data !== undefined && item.data !== 0);
};

const getDailyBarData = (rawData: any, metric: Metric, tz: string) =>
  uniqBy(
    rawData.map((item: any) => {
      const timestamp = `${getTime(
        zonedTimeToUtc(startOfDay((item.timestamp || item.timestamp_from) * 1000), tz)
      )}`;
      const data =
        `${metric}_sum` in item.data ? item.data[`${metric}_sum`] : item.data[`${metric}_time`];
      return {
        timestamp,
        data,
      };
    }),
    'timestamp'
  ).filter((item: any) => item.data !== undefined && item.data !== 0);

const getBloodPressureData = (rawData: any) => {
  if (!isEmpty(rawData)) {
    const data = rawData[0];

    const composedData = [...data.systolic, ...data.diastolic];
    const uniqueTimestamps = uniq(map(composedData, 0));

    return uniqueTimestamps.map((time: number) => {
      const hasDiastolic = data.diastolic.find((d: any) => d[0] === time);
      const hasSystolic = data.systolic.find((d: any) => d[0] === time);
      return {
        timestamp: time * 1000,
        systolic_data: hasSystolic ? hasSystolic[1] : null,
        diastolic_data: hasDiastolic ? hasDiastolic[1] : null,
      };
    });
  }

  return [];
};

const getBloodPressureMinMaxData = (rawData: any, _: Metric, tz: string) =>
  rawData
    .map((item: any) => ({
      timestamp: `${getTime(
        zonedTimeToUtc(startOfDay((item.timestamp || item.timestamp_from) * 1000), tz)
      )}`,
      systolic_data: item.data.systolic_mean,
      systolic_min: item.data.systolic_min,
      systolic_max: item.data.systolic_max,
      diastolic_data: item.data.diastolic_mean,
      diastolic_min: item.data.diastolic_min,
      diastolic_max: item.data.diastolic_max,
    }))
    .filter((item: any) => item.systolic_data || item.diastolic_data);

const getMealsData = (rawData: any, _: Metric, tz: string) => {
  let data: any = [];

  for (const item of rawData) {
    for (const activity of item.activities) {
      const time =
        isMatch(activity.when?.time, 'HH:mm') || isMatch(activity.when?.time, 'hh:mm a')
          ? isMatch(activity.when?.time, 'hh:mm aaaa')
            ? activity.when.time.replace(/\. /g, '')
            : activity.when.time
          : mealDefaultTime[activity.description?.toUpperCase()] ||
            mealDefaultTime[activity.subtitle?.toUpperCase()] ||
            '12:00';
      const date = new Date(`${item.date} ${time}`);
      const meal_time = getUnixTime(date) - getGlobalTimezoneOffset(tz);

      data.push({
        timestamp: meal_time * 1000,
        type:
          mealsTypes[activity.description?.toUpperCase()] ||
          mealsTypes[activity.subtitle?.toUpperCase()] ||
          'LUNCH',
        items: [
          {
            meal_time: meal_time * 1000,
            image: activity?.activityDetails?.imageUrl || '',
            note: activity?.title,
          },
        ],
      });
    }
  }

  return data;
};

const getDailyMealsData = (rawData: any, _: Metric, tz: string) => {
  const timezoneOffset = getGlobalTimezoneOffset(tz);
  return flatMap(
    rawData.map((day: any) => {
      const timestamp = `${(getUnixTime(new Date(day.date)) - timezoneOffset) * 1000}`;
      const groupedMeals = groupBy(day.activities, 'description');
      return Object.values(groupedMeals).map((group: any) => {
        const items = group
          .map((item: any) => {
            const time =
              isMatch(item?.when?.time, 'HH:mm') || isMatch(item?.when?.time, 'hh:mm a')
                ? isMatch(item?.when?.time, 'hh:mm aaaa')
                  ? item?.when?.time.replace(/\. /g, '')
                  : item?.when?.time
                : mealDefaultTime[item.description?.toUpperCase()] ||
                  mealDefaultTime[item.subtitle?.toUpperCase()] ||
                  '12:00';
            const date = new Date(`${day.date} ${time}`);
            const meal_time = getUnixTime(date) - timezoneOffset;
            return {
              meal_time: meal_time * 1000,
              type:
                mealsTypes[item.description?.toUpperCase()] ||
                mealsTypes[item.subtitle?.toUpperCase()] ||
                'LUNCH',
              image: item.activityDetails?.imageUrl || '',
              note: item.title,
            };
          })
          .sort((a: any, b: any) => a.meal_time - b.meal_time);

        return {
          timestamp,
          type:
            mealsTypes[group[0]?.description?.toUpperCase()] ||
            mealsTypes[group[0]?.subtitle?.toUpperCase()] ||
            'LUNCH',
          items,
        };
      });
    })
  );
};

const getSleepDepthData = (rawData: any) =>
  uniqBy(
    rawData.map((item: any) => {
      return {
        start: item[0] * 1000,
        end: item[1] * 1000,
        value: `${item[2]}`,
        timestamp: item[0] * 1000,
      };
    }),
    'timestamp'
  );

const transformConfig: TransformMetricsConfig = {
  basicBar: {
    1: { dataCallback: getHourlyBarData, dimensions: transformDimensions.tick },
    2: { dataCallback: getHourlyBarData, dimensions: transformDimensions.tick },
    7: { dataCallback: getDailyBarData, dimensions: transformDimensions.tick },
    14: { dataCallback: getDailyBarData, dimensions: transformDimensions.tick },
    21: { dataCallback: getDailyBarData, dimensions: transformDimensions.tick },
    28: { dataCallback: getDailyBarData, dimensions: transformDimensions.tick },
  },
  composedBar: {
    1: { dataCallback: getHourlyBarData, dimensions: transformDimensions.tick },
    2: { dataCallback: getHourlyBarData, dimensions: transformDimensions.tick },
    7: { dataCallback: getDailyBarData, dimensions: transformDimensions.tick },
    14: { dataCallback: getDailyBarData, dimensions: transformDimensions.tick },
    21: { dataCallback: getDailyBarData, dimensions: transformDimensions.tick },
    28: { dataCallback: getDailyBarData, dimensions: transformDimensions.tick },
  },
  basicLine: {
    1: { dataCallback: getTickData, dimensions: transformDimensions.tick },
    2: { dataCallback: getTickData, dimensions: transformDimensions.tick },
    7: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    14: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    21: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    28: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
  },
  smaLine: {
    1: { dataCallback: getSmaData, dimensions: transformDimensions.smaTick },
    2: { dataCallback: getSmaData, dimensions: transformDimensions.smaTick },
    7: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    14: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    21: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    28: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
  },
  composedLine: {
    1: { dataCallback: getTickData, dimensions: transformDimensions.tick },
    2: { dataCallback: getTickData, dimensions: transformDimensions.tick },
    7: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    14: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    21: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    28: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
  },
  onePointLine: {
    1: { dataCallback: getTickData, dimensions: transformDimensions.tick },
    2: { dataCallback: getTickData, dimensions: transformDimensions.tick },
    7: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    14: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    21: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    28: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
  },
  bandLine: {
    1: { dataCallback: getBandData, dimensions: transformDimensions.tick },
    2: { dataCallback: getBandData, dimensions: transformDimensions.tick },
    7: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    14: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    21: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
    28: { dataCallback: getMinMaxData, dimensions: transformDimensions.minMax },
  },
  bloodPressure: {
    1: { dataCallback: getBloodPressureData, dimensions: transformDimensions.bloodPressureTick },
    2: { dataCallback: getBloodPressureData, dimensions: transformDimensions.bloodPressureTick },
    7: {
      dataCallback: getBloodPressureMinMaxData,
      dimensions: transformDimensions.bloodPressureMinMax,
    },
    14: {
      dataCallback: getBloodPressureMinMaxData,
      dimensions: transformDimensions.bloodPressureMinMax,
    },
    21: {
      dataCallback: getBloodPressureMinMaxData,
      dimensions: transformDimensions.bloodPressureMinMax,
    },
    28: {
      dataCallback: getBloodPressureMinMaxData,
      dimensions: transformDimensions.bloodPressureMinMax,
    },
  },
  mealsDots: {
    1: { dataCallback: getMealsData, dimensions: transformDimensions.mealsTick },
    2: { dataCallback: getMealsData, dimensions: transformDimensions.mealsTick },
    7: { dataCallback: getDailyMealsData, dimensions: transformDimensions.mealsTick },
    14: { dataCallback: getDailyMealsData, dimensions: transformDimensions.mealsTick },
    21: { dataCallback: getDailyMealsData, dimensions: transformDimensions.mealsTick },
    28: { dataCallback: getDailyMealsData, dimensions: transformDimensions.mealsTick },
  },
  logDots: {
    1: { dataCallback: getMealsData, dimensions: transformDimensions.mealsTick },
    2: { dataCallback: getMealsData, dimensions: transformDimensions.mealsTick },
    7: { dataCallback: getDailyMealsData, dimensions: transformDimensions.mealsTick },
    14: { dataCallback: getDailyMealsData, dimensions: transformDimensions.mealsTick },
    21: { dataCallback: getDailyMealsData, dimensions: transformDimensions.mealsTick },
    28: { dataCallback: getDailyMealsData, dimensions: transformDimensions.mealsTick },
  },
  sleepDepth: {
    1: { dataCallback: getSleepDepthData, dimensions: transformDimensions.sleepDepthTick },
    2: { dataCallback: getSleepDepthData, dimensions: transformDimensions.sleepDepthTick },
    7: { dataCallback: getSleepDepthData, dimensions: transformDimensions.sleepDepthTick },
    14: { dataCallback: getSleepDepthData, dimensions: transformDimensions.sleepDepthTick },
    21: { dataCallback: getSleepDepthData, dimensions: transformDimensions.sleepDepthTick },
    28: { dataCallback: getSleepDepthData, dimensions: transformDimensions.sleepDepthTick },
  },
};

export const transformTimestamp: ExternalTransform = {
  type: 'transform:timestamp',
  transform: function transform(params: TransformParams) {
    const rawData = params.upstream.cloneRawData();
    const { dateRange, metric, types, tz, data } = params.config as TransformParamsConfig;
    if (metric.includes('_sma')) {
      const chartType = 'smaLine';
      const { dataCallback, dimensions } = transformConfig[chartType][dateRange];
      const data = dataCallback(rawData, metric, tz);
      return [
        {
          dimensions,
          data,
        },
      ];
    } else {
      const baseMetric = metric in parentMetric ? parentMetric[metric] : metric;
      const chartType = types[baseMetric];
      const { dataCallback, dimensions } = transformConfig[chartType][dateRange];
      const result = dataCallback(rawData, metric, tz, data);

      return [
        {
          dimensions,
          data: result,
        },
      ];
    }
  },
};

export function getLastValue(data: any, metric: Metric | ComposedMetric, dateRange: number) {
  switch (metric) {
    case 'temp':
      return data.skin_temp?.length ? round(data.skin_temp[data.skin_temp.length - 1][1], 2) : '';
    case 'steps':
      const filterSteps = data.steps?.filter((step: any) => step.data.steps_sum);
      return filterSteps?.length
        ? round(filterSteps[filterSteps.length - 1].data.steps_sum, 2)
        : '';
    case 'rhr':
      return data.rhr?.length ? round(data.rhr[data.rhr?.length - 1]?.data?.rhr_mean, 2) : '';
    case 'meals':
      return data.meals?.length ? data.meals[0].activities?.length : '';
    case 'spo2':
      return data.spo2?.length ? round(data.spo2[data.spo2.length - 1][1] * 100, 2) : '';
    case 'weight':
      const weightData = data.weight?.filter((item: any) => item[1] !== 0);
      return weightData?.length
        ? round(weightData[weightData?.length - 1][1] * KG_TO_POUND, 1)
        : '';
    case 'blood_pressure':
      if (dateRange > 2) {
        const lastBpData = data?.general?.length ? data?.general[data?.general?.length - 1] : null;
        return lastBpData?.data
          ? `${round(lastBpData.data.systolic_mean, 2)}/${round(lastBpData.data.diastolic_mean, 2)}`
          : '';
      }
      return data.systolic?.length && data.diastolic.length
        ? `${round(data.systolic[data.systolic.length - 1][1], 2)}/${round(
            data.diastolic[data.diastolic.length - 1][1],
            2
          )}`
        : '';
    case 'sleep':
      return data[metric]?.length
        ? sleepDepthLevels[(data[metric][data[metric].length - 1][2] || 1) as SleepDepthOption]
        : '';
    case 'band_removed':
      return data.band_removed?.length
        ? rawBandStates[
            (data.band_removed[data.band_removed.length - 1][1] + 1) as BandRemovedOption
          ]
        : '';
    default:
      const filteredData = data[metric]?.filter((item: any) => item[1] !== 0);
      return filteredData?.length ? round(filteredData[filteredData?.length - 1][1], 2) : '';
  }
}

export const getFactsRequestInfo = (evidenceData: ActionEvidenceData[], endTime: number) => {
  const metricsFacts = evidenceData
    .filter(
      (item: ActionEvidenceData) =>
        !item.metricsArray.includes('meals') && !item.metric.includes('band_removed')
    )
    .flatMap((item: ActionEvidenceData) => {
      return item.metricsArray?.map((metric) => [
        `${factsMeanMetrics[metric]}_${item.timeAvg}_at_${endTime}`,
        `${factsMeanMetrics[metric]}_${item.timeBaseline}_at_${endTime}`,
      ]);
    });
  return metricsFacts.join(',');
};

export const getExForSma = (x1: number, x2: number, y1: number, y2: number) => {
  const slope = (y2 - y1) / (x2 - x1);
  return function (x: number) {
    return slope * (x - x1) + y1;
  };
};

export const getIntersection = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number
) => {
  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false;
  }

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  // Lines are parallel
  if (denominator === 0) {
    return false;
  }

  let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false;
  }

  // Return a object with the x and y coordinates of the intersection
  let x = x1 + ua * (x2 - x1);
  let y = y1 + ua * (y2 - y1);

  return { x, y };
};
